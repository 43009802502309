import 'babel-polyfill'
import 'isomorphic-fetch'
import React from 'react'
import { graphql, withPrefix, Link } from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'

import Layout from '../../../components/layout'
import NavSubTour from '../../../components/nav/navSubTour'

import HowWorksVideo from '../../../components/marketing/howWorksVideo'

import { FaCommentDots, FaRobot, FaShare } from 'react-icons/fa'

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const videoLoader = [
      {
        id: 1,
        url:
          'https://storylavamedia-prod.s3.amazonaws.com/static/mkt/tour/01_notification.mp4',
        retinaurl:
          'https://storylavamedia-prod.s3.amazonaws.com/static/mkt/tour/01_notification.mp4',
      },
      {
        id: 2,
        url:
          'https://storylavamedia-prod.s3.amazonaws.com/static/mkt/tour/02_templateedit.mp4',
        retinaurl:
          'https://storylavamedia-prod.s3.amazonaws.com/static/mkt/tour/02_templateedit.mp4',
      },
      {
        id: 3,
        url:
          'https://storylavamedia-prod.s3.amazonaws.com/static/mkt/tour/03_share.mp4',
        retinaurl:
          'https://storylavamedia-prod.s3.amazonaws.com/static/mkt/tour/03_share.mp4',
      },
      {
        id: 4,
        url:
          'https://storylavamedia-prod.s3.amazonaws.com/static/mkt/tour/04_templatesort.mp4',
        retinaurl:
          'https://storylavamedia-prod.s3.amazonaws.com/static/mkt/tour/04_templatesort.mp4',
      },
      {
        id: 5,
        url:
          'https://storylavamedia-prod.s3.amazonaws.com/static/mkt/tour/05_editfeatures.mp4',
        retinaurl:
          'https://storylavamedia-prod.s3.amazonaws.com/static/mkt/tour/05_editfeatures.mp4',
      },
    ]
    const benefitText = [
      {
        title: 'Enable sales affiliates with brand templates',
        content:
          'Equip sales reps with on-brand templates to create and distribute social content',
        activeVideo: 1,
        startTime: 0,
        endTime: 18,
      },

      {
        title: 'Edit & customize social content templates',
        content:
          'Let teams quickly personalize images & videos from corporate templates - including text, colors, styling and more',
        activeVideo: 2,
        startTime: 0,
        endTime: 30,
      },

      {
        title: 'Simple, rapid social sharing',
        content:
          'Quickly share customized content directly to social media to drive sales, or download to share',
        activeVideo: 3,
        startTime: 0,
        endTime: 38,
      },

      {
        title: 'Sort & categorize templates',
        content:
          'Compose templates for different campaigns, categorize with tags, and distribute to sales affiliates',
        activeVideo: 4,
        startTime: 0,
        endTime: 11,
      },

      {
        title: 'Advanced editing',
        content:
          'Use intuitive features to customize templates: change colors & text, upload images, crop photos and more',
        activeVideo: 5,
        startTime: 0,
        endTime: 25,
      },
    ]

    return (
      <Layout>
        <Helmet
          title="Storylava - Tour - Personalized video"
          meta={[
            {
              name: 'description',
              content:
                'Take a tour of the Storylava visual engagement platform with tools to rapidly produce social graphics and videos from templates',
            },
          ]}
        />
        <NavSubTour />
        <HowWorksVideo
          context="brand"
          title="Equip sales teams with on-brand social content"
          description="Let reps quickly personalize images & videos from corporate templates. Take a tour below:"
          content={benefitText}
          videos={videoLoader}
        />
      </Layout>
    )
  }
}

export default Snippet

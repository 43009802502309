import React from 'react'

const Snippet = (props) => (
  <div
    class={`about-detail-icon-outer-container d-flex flex-column align-items-center justify-content-center bg-blue-${
      props.active === true ? '4' : '5'
    }`}
    style={{
      width: 40,
      height: 40,
      borderRadius: 40,
      minWidth: 40,
    }}
  >
    <div
      class={`about-detail-icon-container d-flex flex-column align-items-center justify-content-center bg-blue-${
        props.active === true ? '2' : '4'
      }`}
      style={{ width: 30, height: 30, color: '#fff' }}
    >
      {props.number}
    </div>
  </div>
)

export default Snippet

import React from 'react'

const Snippet = (props) => (
  <div class="pl-md-4 pb-3">
    <h1 class="text-center text-md-left" style={{ fontSize: '2.2rem' }}>
      Tour
    </h1>
    {/* <h2 class="text-center text-md-left">{props.title}</h2> */}
    <p class="card-sub text-center text-md-left">{props.description}</p>
  </div>
)

export default Snippet

import React from 'react'
import { Link } from 'gatsby'

import './../../scss/main.scss'

export default class Nav extends React.Component {
  render() {
    return (
      <nav class="navbar navbar-sub navbar-expand-lg bg-blue-1">
        <div class="container">
          <div
            class="d-flex flex-row align-items-center justify-content-center"
            id="navbarChallenge"
          >
            {/* <div
              class="card-title-solo black-3 text-right pr-4"
              style={{ width: "160px" }}
            >
              Challenges
            </div> */}
            <ul class="d-flex flex-row align-items-center justify-content-center navbar-nav mr-auto">
              {/* <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link black-1 active"
                  to="/about/tour/testimonials/"
                >
                  Customer testimonials
                </Link>
              </li> */}
              <li class="nav-item pr-3">
                <Link
                  class="nav-link white-3 font-weight-light"
                  activeClassName="nav-link white-1 active font-weight-light text-decoration-underline"
                  to="/about/tour/brands/"
                >
                  For brands
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

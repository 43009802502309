// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import LoadingSpinner from '../loading'
import VolumeAnimation from './volumeAnimation'

import HowWorksVideoNumber from './howWorksVideoNumber'
import HowWorksVideoBrandDescription from './howWorksVideoBrandDescription'
import { FaArrowRight } from 'react-icons/fa'
import {
  MdCheck,
  MdOndemandVideo,
  MdPhonelinkRing,
  MdVolumeOff,
  MdVolumeUp,
  MdVolumeMute,
} from 'react-icons/md'

const agentParser = require('ua-parser-js')

export default class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeVideo: 1,
      currentVideoTime: 0,
      currentVideo2Time: 0,
      currentVideo3Time: 0,
      currentVideo4Time: 0,
      currentVideo5Time: 0,
      currentPosition: 1,
      soundActivated: 'initial',
      currentBrowser: '',
    }
    this.handleSoundActivation = this.handleSoundActivation.bind(this)
  }
  componentDidMount() {
    const agentObj = agentParser(window.navigator.userAgent)
    this.setState({
      currentBrowser: agentObj.browser.name,
    })
  }
  handleSoundActivation() {
    const { videos } = this.props
    if (
      this.state.soundActivated === false ||
      this.state.soundActivated === 'initial'
    ) {
      this.setState({ soundActivated: true })

      this.handleUpdatedPosition(
        this.state.currentPosition,
        this.state.activeVideo,
        0
      )
      videos.map((video) => {
        this[`video${video.id}`].muted = false
        this[`video${video.id}`].volume = 0
      })

      this[`video${this.state.activeVideo}`].volume = 1
    } else {
      this.setState({ soundActivated: false })
      videos.map((video) => {
        this[`video${video.id}`].muted = false
        this[`video${video.id}`].volume = 0
      })
    }
  }
  handleTimeUpdate(videoMode) {
    this.setState({
      currentVideoTime: this.video1.currentTime,
      currentVideo2Time: this.video2.currentTime,
      currentVideo3Time: this.video3.currentTime,
      currentVideo4Time: this.video4.currentTime,
      currentVideo5Time: this.video5.currentTime,
    })
    // if (this.state.activeVideo === 1 && this.video1.currentTime < 21.0) {
    //   this.setState({ activeVideo: 1, currentPosition: 1 })
    // } else if (
    //   this.state.activeVideo === 1 &&
    //   this.video1.currentTime >= 21.0
    // ) {
    //   console.log('**advancing to VIDEO 2')
    //   this.setState({
    //     activeVideo: 2,
    //     currentPosition: 2,
    //   })
    //   this.video1.pause()
    //   this.video1.currentTime = 0
    //   this.video1.volume = 0

    //   // this.video2.autoplay = true
    //   this.video2.load()
    //   this.video2.volume = this.state.soundActivated ? 1 : 0
    //   // this.video2.play()
    // } else if (
    //   this.state.activeVideo === 2 &&
    //   this.video2.currentTime >= 25.8
    // ) {
    //   console.log('**advancing to VIDEO 3')

    //   this.setState({ currentPosition: 3, activeVideo: 3 })
    //   this.video2.pause()
    //   this.video2.currentTime = 0
    //   this.video2.volume = 0

    //   // this.video3.play()
    //   // this.video3.autoplay = true
    //   this.video3.load()
    //   this.video3.volume = this.state.soundActivated ? 1 : 0
    // } else if (
    //   this.state.activeVideo === 3 &&
    //   this.video3.currentTime >= 24.0
    // ) {
    //   console.log('**advancing to VIDEO 4')

    //   this.setState({ currentPosition: 4, activeVideo: 4 })
    //   // this.video3.pause()
    //   this.video3.currentTime = 0
    //   this.video3.volume = 0

    //   // this.video4.play()
    //   // this.video4.autoplay = true
    //   this.video4.load()
    //   this.video4.volume = this.state.soundActivated ? 1 : 0
    // } else if (
    //   this.state.activeVideo === 4 &&
    //   this.video4.currentTime >= 12.3
    // ) {
    //   console.log('**advancing to VIDEO 5')

    //   this.setState({ currentPosition: 5, activeVideo: 5 })
    //   // this.video4.pause()
    //   this.video4.currentTime = 0
    //   this.video4.volume = 0

    //   // this.video5.play()
    //   this.video5.autoplay = true
    //   this.video5.load()
    //   this.video5.volume = this.state.soundActivated ? 1 : 0
    // } else if (
    //   this.state.activeVideo === 5 &&
    //   this.video5.currentTime >= 28.8
    // ) {
    //   console.log('**advancing BACK to VIDEO 3')

    //   this.setState({ currentPosition: 1, activeVideo: 1 })
    //   // this.video5.pause()
    //   this.video5.currentTime = 0
    //   this.video5.volume = 0

    //   // this.video1.play()
    //   this.video1.autoplay = true
    //   this.video1.load()
    //   this.video1.volume = this.state.soundActivated ? 1 : 0
    // }
  }
  handleUpdatedPosition(currentPosition, activeVideo, startTime, endTime) {
    console.log('handleUpdatedPosition :: this.state.activeVideo')
    console.log(this.state.activeVideo)
    console.log('handleUpdatedPosition :: activeVideo')
    console.log(activeVideo)

    const { videos } = this.props

    this.setState({
      currentPosition: currentPosition,
      activeVideo: activeVideo,
      soundActivated: true,
    })

    videos.map((video) => {
      this[`video${video.id}`].muted = false
      this[`video${video.id}`].volume = 0
    })

    this[`video${this.state.activeVideo}`].pause()
    this[`video${this.state.activeVideo}`].currentTime = 0

    // this[`video${activeVideo}`].muted = false
    this[`video${activeVideo}`].currentTime = 0
    this[`video${activeVideo}`].volume = 1
    // if (
    //   this.state.soundActivated === false ||
    //   this.state.soundActivated === 'initial'
    // ) {
    //   this[`video${activeVideo}`].volume = 0
    // } else if (this.state.soundActivated === true) {
    //   // nextVideoVolume = 1

    //   this[`video${activeVideo}`].volume = 1
    // }
    if (this.state.currentBrowser === 'Mobile Safari') {
      this[`video${activeVideo}`].load()
    } else {
      this[`video${activeVideo}`].play()
    }
  }

  render() {
    const { videos } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            workflowVideoFrame: file(
              relativePath: { eq: "feature/iphone-frame.png" }
            ) {
              childImageSharp {
                fixed(width: 275, height: 568, cropFocus: NORTH) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            {/* AV: {this.state.activeVideo} | CP: {this.state.currentPosition} |
            V1: {this.state.currentVideoTime} | V2:{' '}
            {this.state.currentVideo2Time} | V3: {this.state.currentVideo3Time}{' '}
            | V4: {this.state.currentVideo4Time} | V5:{' '}
            {this.state.currentVideo5Time} */}
            <div class="container">
              <div class="row d-flex my-5 py-2">
                <div class="col-md-4 p-0">
                  <div class="d-block d-md-none">
                    <HowWorksVideoBrandDescription
                      title={this.props.title}
                      description={this.props.description}
                    />
                  </div>

                  {/* BEGIN SECTION */}
                  <div class="d-flex align-items-center justify-content-center videotour-container">
                    {this.state.currentBrowser === 'IE' ? null : (
                      <Img
                        alt="How it works"
                        fixed={data.workflowVideoFrame.childImageSharp.fixed}
                        style={{ position: 'absolute', zIndex: 10 }}
                        fadeIn={false}
                      />
                    )}

                    {/* All the weird top/right/left/bottom styling is to support IE 11. See: https://stackoverflow.com/questions/22654528/how-to-center-a-position-absolute-element-in-ie-9-10-11 */}
                    <div
                      class="videotour"
                      style={{
                        zIndex: -30,
                        width: 255,
                        height: 552,
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        margin: 'auto',
                        position: 'absolute',
                      }}
                    >
                      <LoadingSpinner />
                    </div>

                    {this.props.videos.map((item, i) => (
                      <video
                        ref={(e) => {
                          this[`video${i + 1}`] = e
                        }}
                        key={`video${i + 1}`}
                        className={
                          this.state.activeVideo === i + 1
                            ? 'd-block'
                            : 'd-none'
                        }
                        // onLoadEnd={() => {
                        //   if (
                        //     this.state.soundActivated === false ||
                        //     this.state.soundActivated === 'initial'
                        //   ) {

                        //     // this[`video${nextVideo}`].muted = false
                        //     this[`video${video.id}`].muted = false
                        //     this[`video${video.id}`].volume = 0
                        //   } else if (this.state.soundActivated === true) {
                        //     // nextVideoVolume = 1
                        //     // this[`video${nextVideo}`].muted = false
                        //     this[`video${video.id}`].muted = false
                        //     this[`video${video.id}`].volume = 1
                        //   }
                        // }}
                        onEnded={(e) => {
                          console.log('**firing onEnded**')
                          let currentVideo = i + 1
                          let nextVideo = i + 2
                          if (nextVideo === 6) nextVideo = 1
                          console.log('**advancing to VIDEO 2')
                          if (this.state.activeVideo === currentVideo) {
                            this.setState({
                              activeVideo: nextVideo,
                              currentPosition: nextVideo,
                            })
                            this[`video${currentVideo}`].pause()
                            this[`video${currentVideo}`].currentTime = 0
                            this[`video${currentVideo}`].volume = 0

                            // this.video2.autoplay = true

                            // videos.map((video) => {
                            //   this[`video${video.id}`].muted = false
                            //   this[`video${video.id}`].volume = 0
                            // })

                            if (
                              this.state.soundActivated === false ||
                              this.state.soundActivated === 'initial'
                            ) {
                              // this[`video${nextVideo}`].muted = false
                              // this[`video${nextVideo}`].volume = 0
                            } else if (this.state.soundActivated === true) {
                              // nextVideoVolume = 1
                              // this[`video${nextVideo}`].muted = false
                              this[`video${nextVideo}`].volume = 1
                            }
                            this[`video${nextVideo}`].load()
                          }
                        }}
                        onVolumeChange={(e) => {
                          if (this[`video${i + 1}`].currentTime > 0) {
                            if (this[`video${i + 1}`].muted === true) {
                              this.setState({
                                soundActivated: false,
                              })
                              videos.map((video) => {
                                if (video.id !== i + 1) {
                                  // console.log('i+1')
                                  // console.log(i + 1)
                                  // console.log('video.id')
                                  // console.log(video.id)

                                  this[`video${video.id}`].muted = false
                                  this[`video${video.id}`].volume = 0
                                }
                              })
                            } else {
                              this.setState({
                                soundActivated: true,
                              })
                              videos.map((video) => {
                                if (video.id !== i + 1) {
                                  // console.log('i+1')
                                  // console.log(i + 1)
                                  // console.log('video.id')
                                  // console.log(video.id)

                                  this[`video${video.id}`].muted = false
                                  this[`video${video.id}`].volume = 0
                                } else if (video.id === i + 1) {
                                  this[`video${i + 1}`].muted = false
                                  this[`video${i + 1}`].volume = 1
                                }
                              })
                            }
                          }
                        }}
                        // onVolumeChange={(e) => {
                        //   console.log('************* START')
                        //   console.log('VIDEO :: onVolumeChange - e')
                        //   console.log(e)
                        //   console.log('VIDEO :: onVolumeChange - e.target')
                        //   console.log(e.target)
                        //   console.log(
                        //     'VIDEO :: onVolumeChange - e.target.muted'
                        //   )
                        //   console.log(e.target.muted)
                        //   console.log(
                        //     'VIDEO :: onVolumeChange - this.state.soundActivated'
                        //   )
                        //   console.log(this.state.soundActivated)
                        //   console.log('VIDEO :: currentVideo - i')
                        //   console.log(`video${i + 1}`)
                        //   console.log(`VIDEO :: this/muted - video${i + 1}`)
                        //   console.log(this[`video${i + 1}`].muted)
                        //   console.log(
                        //     `VIDEO :: this/currentTime - video${i + 1}`
                        //   )
                        //   console.log(this[`video${i + 1}`].currentTime)
                        //   console.log('************* END')

                        //   if (this[`video${i + 1}`].currentTime > 0) {
                        //     if (this[`video${i + 1}`].muted === true) {
                        //       this.video1.muted = false
                        //       this.video2.muted = false
                        //       this.video3.muted = false
                        //       this.video4.muted = false
                        //       this.video5.muted = false
                        //       this.video1.volume = 0
                        //       this.video2.volume = 0
                        //       this.video3.volume = 0
                        //       this.video4.volume = 0
                        //       this.video5.volume = 0
                        //       this.setState({
                        //         soundActivated: false,
                        //       })

                        //       // this[`video${i+1}`].currentTime = startTime
                        //       this[`video${i + 1}`].volume = 1
                        //     } else {
                        //       this.video1.muted = false
                        //       this.video2.muted = false
                        //       this.video3.muted = false
                        //       this.video4.muted = false
                        //       this.video5.muted = false
                        //       this.video1.volume = 0
                        //       this.video2.volume = 0
                        //       this.video3.volume = 0
                        //       this.video4.volume = 0
                        //       this.video5.volume = 0
                        //       this.setState({
                        //         soundActivated: true,
                        //       })

                        //       // this[`video${i+1}`].currentTime = startTime
                        //       this[`video${i + 1}`].volume = 1
                        //     }
                        //   }
                        // }}
                        onTimeUpdate={this.handleTimeUpdate.bind(
                          this,
                          this.props.context
                        )}
                        width="255"
                        height="552"
                        playsInline
                        autoPlay
                        muted
                        controls="controls"
                        // loop="loop"
                        style={{
                          zIndex: 10,
                          borderRadius: 20,
                          width: 255,
                          height: 552,
                        }}
                      >
                        {typeof window !== `undefined` &&
                        window.devicePixelRatio >= 2 ? (
                          <source src={item.retinaurl} type="video/mp4" />
                        ) : (
                          <source src={item.url} type="video/mp4" />
                        )}
                      </video>
                    ))}
                  </div>
                </div>

                <div class="col-md-7 d-flex flex-column align-items-center justify-content-center align-items-md-start justify-content-md-start">
                  <div class="d-none d-md-block">
                    <HowWorksVideoBrandDescription
                      title={`${this.props.title}`}
                      description={this.props.description}
                    />
                  </div>
                  <div class="p-3 videotour-features">
                    <div
                      class="d-none d-md-block"
                      style={{
                        width: 0,
                        height: 0,
                        borderTop: '20px solid transparent',
                        borderBottom: '20px solid transparent',
                        borderRight: '20px solid #307adb',
                        position: 'absolute',
                        left: 5,
                        top: 230,
                      }}
                    />
                    {/* <hr size="1" /> */}
                    <div class="d-flex flex-row align-items-center justify-content-center align-items-md-center justify-content-md-start">
                      {this.props.content.map((item, i) => (
                        <div
                          class="videotour-number-container d-flex flex-column align-items-center justify-content-center"
                          onClick={() =>
                            this.handleUpdatedPosition(
                              i + 1,
                              item.activeVideo,
                              item.startTime,
                              item.endTime
                            )
                          }
                        >
                          <HowWorksVideoNumber
                            number={`${i + 1}`}
                            active={
                              this.state.currentPosition === i + 1
                                ? true
                                : false
                            }
                          />
                        </div>
                      ))}
                      <button
                        onClick={this.handleSoundActivation}
                        class="d-flex align-items-center justify-content-center btn bg-gray-7 ml-3"
                        style={{
                          border: 0,
                          background: 'transparent',
                          // width: '100%',
                          // border: '1px solid green',
                          // width: 110,
                          // height: 110,
                        }}
                      >
                        {this.state.soundActivated === 'initial' ? (
                          <div
                            style={{
                              width: 40,
                              height: 40,
                              overflow: 'hidden',
                            }}
                          >
                            <VolumeAnimation />
                          </div>
                        ) : this.state.soundActivated === false ? (
                          <MdVolumeOff
                            size={34}
                            style={{ marginTop: 6, marginBottom: 8 }}
                          />
                        ) : (
                          <MdVolumeUp
                            size={34}
                            style={{ marginTop: 6, marginBottom: 8 }}
                          />
                        )}
                      </button>
                    </div>
                    <div
                      style={{
                        borderBottom: '1px solid #e6e6e6',
                        marginTop: 12,
                      }}
                    />
                    {this.props.content.map((item, i) =>
                      this.state.currentPosition === i + 1 ? (
                        <div class="p-2 fade-in text-center text-md-left">
                          <h3>{item.title}</h3>
                          <div class="benefit-text">{item.content}</div>
                        </div>
                      ) : null
                    )}
                  </div>

                  <div class="row d-flex flex-row align-items-center align-items-md-end justify-content-center text-center mt-4 ml-md-2 flex-grow-1">
                    {this.props.context === 'brand' ? (
                      <Link
                        to="/about/features/"
                        class="btn btn-outline-primary btn-block ml-md-3"
                      >
                        See more features{' '}
                        <FaArrowRight
                          style={{
                            color: '#307adb',
                            fontSize: 15,
                            marginBottom: 3,
                          }}
                        />
                      </Link>
                    ) : null}
                    {/* {this.props.context === 'review' ? (
                      <Link
                        to="/about/testimonials/"
                        class="btn btn-cta btn-block ml-3"
                      >
                        Storylava for customer testimonials{' '}
                        <FaArrowRight
                          style={{
                            color: '#307adb',
                            fontSize: 15,
                            marginBottom: 3,
                          }}
                        />
                      </Link>
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      />
    )
  }
}

import React from 'react'
import lottie from 'lottie-web'

export default class Snippet extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById('animationVolume'), // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      // path: 'https://storylavamedia.s3.amazonaws.com/static/icons/volume.json', // the path to the animation json
      path: '/volume.json', // the path to the animation json
    })
  }
  render() {
    return (
      <div
        id="animationVolume"
        style={{
          padding: 0,
          width: 80,
          height: 80,
          marginTop: -20,
          marginBottom: -20,
          marginLeft: -20,
          display: 'block',
        }}
      ></div>
    )
  }
}
